<template>
    <div>
        <CompModal class="comp-model records-model" ref="records_model" title="操作记录" width="700px">
            <div class="screen-box">
                <div class="item-box">
                    <Input v-model="recordScreen.operator" placeholder="操作人" style="width: 220px" />
                </div>
                <div class="item-box">
                    <DatePicker v-model="recordScreen.date" type="daterange" placement="bottom-end" placeholder="选择时间" style="width: 220px" />
                </div>
                <div class="btn" @click="onSubmitRecordScreen">查询</div>
                <div class="btn reset" @click="onResetRecordScreen">重置</div>
            </div>
            <div class="table-box" @scroll="onScrollToLower">
                <div class="base-box">
                    <div class="content-box">
                        <div class="head">
                            <div class="item" style="width:200px">操作人</div>
                            <div class="item" style="width:120px">操作类型</div>
                            <div class="item" style="width:200px">操作时间</div>
                        </div>
                        <div class="body">
                            <div class="row" v-for="(item, idx) in operatingRecords" :key="idx">
                                <div class="column" style="width:200px">
                                    <div class="column-box">
                                        <p class="text-box">{{ item.operator }}</p>
                                    </div>
                                </div>
                                <div class="column" style="width:120px">
                                    <div class="column-box">
                                        <p class="text-box">{{ item.operateType }}</p>
                                    </div>
                                </div>
                                <div class="column" style="width:200px">
                                    <div class="column-box">
                                        <p class="text-box">{{ item.operateTime }}</p>
                                    </div>
                                </div>
                            </div>
                            <p class="empty" v-if="!operatingRecords || 0 >= operatingRecords.length">当前数据为空</p>
                        </div>
                    </div>
                    <div class="operating-box" :class="{ 'operating-suspension': true }">
                        <div class="operating-title">操作</div>

                        <div class="operating-item" v-for="(item, idx) in operatingRecords" :key="idx">
                            <div class="button-box" v-if="item.operateType === '修改'">
                                <!-- 修改 -->
                                <a class="edit" @click="onDisplayUpdateDetail(item)">查看详情</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </CompModal>
        <CompModal class="comp-model" ref="update_model" title="更新记录" width="900px">
            <div class="table-box">
                <div class="base-box">
                    <div class="content-box">
                        <div class="head">
                            <div class="item" style="width:150px">修改项</div>
                            <div class="item" style="width:250px">修改前</div>
                            <div class="item" style="width:250px">修改后</div>
                        </div>
                        <div class="body">
                            <div class="row" v-for="(item, idx) in updateRecords" :key="idx">
                                <div class="column" style="width:150px">
                                    <div class="column-box">
                                        <p class="text-box">{{ item.fieldName }}</p>
                                    </div>
                                </div>
                                <div class="column" style="width:250px">
                                    <div class="column-box">
                                        <p class="text-box">{{ item.oldValue || "-" }}</p>
                                    </div>
                                </div>
                                <div class="column" style="width:250px">
                                    <div class="column-box">
                                        <p class="text-box">{{ item.newValue }}</p>
                                    </div>
                                </div>
                            </div>
                            <p class="empty" v-if="!updateRecords || 0 >= updateRecords.length">当前数据为空</p>
                        </div>
                    </div>
                    <!-- <div class="operating-box" :class="{ 'operating-suspension': true }">
                <div class="operating-title">操作</div>

                <div class="operating-item" v-for="(item, idx) in updateRecords" :key="idx">
                    <div class="button-box">
                        <a class="edit" @click="onChangeUpdateRecordsStatus(item.id, 1)">增加</a>
                    </div>
                    <div class="button-box">
                        <a class="entity" @click="onChangeUpdateRecordsStatus(item.id, 2)">更新</a>
                    </div>
                    <div class="button-box">
                        <a class="delete" @click="onChangeUpdateRecordsStatus(item.id, 3)">忽略</a>
                    </div>
                </div>
            </div> -->
                </div>
            </div>
        </CompModal>
    </div>
</template>

<script>
import RequestPage from "../../jointly/utils/page"
import CompModal from "../components/CompModal.vue"

export default {
    components: { CompModal },

    data() {
        return {
            recordScreen: {},
            operatingRecords: null,
            // 更新记录
            updateRecords: null,
        }
    },

    methods: {
        display(id) {
            this.recordScreen = {}
            this.operatingRecords = null
            this.type = id ? "id" : "all"

            if (id) {
                this.record_id = id
                this.getAllOperatingRecords()
                return
            }

            this.reqPage = new RequestPage("/syaa/api/syuser/pc/uaUserInfo/findUserLogAll", {
                load: false,
                onLoadAfter: () => {
                    this.$refs.records_model.display()
                },
                onChange: res => {
                    this.operatingRecords = res
                },
            })

            this.reqPage.reset()
        },

        /**
         * 滚动到底部触发事件
         */
        onScrollToLower(evt) {
            if (this.type !== "all") {
                return
            }

            const t = evt.target
            if (t.scrollHeight - 10 <= t.scrollTop + t.clientHeight) {
                if (!this.bottoming) {
                    this.reqPage.load()
                }
                this.bottoming = true
            } else {
                this.bottoming = false
            }
        },

        /**
         * 获取所有操作记录
         */
        getAllOperatingRecords() {
            const screen = this.recordScreen
            var startDate, endDate

            if (screen.date) {
                startDate = this.$core.formatDate(screen.date[0], "yyyy-MM-dd")
                endDate = this.$core.formatDate(screen.date[1], "yyyy-MM-dd")
            }

            this.$get("/syaa/api/syuser/pc/uaUserInfo/findUserLogById", {
                ubaseId: this.record_id,
                operator: screen.operator,
                startDate,
                endDate,
            }).then(res => {
                if (res.code == 200) {
                    this.operatingRecords = res.dataList

                    if (!this.$refs.records_model.isDisplay()) {
                        this.$refs.records_model.display()
                    }
                }
            })
        },

        /**
         * 重置记录检索
         */
        onResetRecordScreen() {
            this.reqPage.reset()
        },

        /**
         * 提交记录检索
         */
        onSubmitRecordScreen() {
            if (this.type === "id") {
                return this.getAllOperatingRecords()
            }

            const screen = this.recordScreen
            var startDate, endDate

            if (screen.date && screen.date?.length == 2 && screen.date[0] instanceof Date && screen.date[1] instanceof Date) {
                startDate = this.$core.formatDate(screen.date[0], "yyyy-MM-dd")
                endDate = this.$core.formatDate(screen.date[1], "yyyy-MM-dd")
            }

            this.reqPage.setData({
                startDate,
                endDate,
                operator: screen.operator,
            })
        },

        onDisplayUpdateDetail(v) {
            this.$get("/syaa/api/syuser/pc/uaUserInfo/findUpdateByLogId", {
                logId: v.logId,
            }).then(res => {
                if (res.code == 200) {
                    this.updateRecords = res.dataList
                    this.$refs.update_model.display()
                }
            })
        },

        /**
         * 改变更新记录状态
         */
        onChangeUpdateRecordsStatus(id, val) {
            this.$post("/syaa/api/syuser/pc/uaUserInfo/confirmUpdateById", {
                id: id,
                confirmStatus: val,
            }).then(res => {
                if (res.code == 200) {
                    this.$Message.success({
                        content: "提交成功",
                        background: true,
                    })
                } else
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
            })
        },
    },
}
</script>

<style lang="less">
.records-model {
    .scroll-content {
        display: flex;
        flex-direction: column;
    }

    .screen-box {
        padding: 5px;
        display: flex;
        overflow: auto;

        .item-box {
            margin: 5px;
            flex-shrink: 0;
        }

        .btn {
            margin: 5px;
            cursor: pointer;
            padding: 5px 0;
            width: 105px;
            color: #fff;
            background: #2faaf7;
            font-size: 14px;
            border: 0;
            display: flex;
            -webkit-transition: all 0.3s;
            transition: all 0.3s;
            -webkit-transition-timing-function: ease;
            transition-timing-function: ease;
            border-radius: 5px;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;

            &.reset {
                background: #19be6b;
            }
        }
    }

    .table-box {
        flex: 1;
        height: initial;
        margin-top: 0;
    }
}

.comp-model .table-box {
    position: relative;
    width: 100%;
    height: ~"calc(100% - 10px)";
    margin-top: 10px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    display: flex;
    flex-grow: 1;
    border-bottom: 1px solid #f3f3f3;

    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    .base-box {
        min-width: 100%;
        flex-shrink: 0;
        display: flex;
        align-items: flex-start;

        .content-box {
            min-height: 100%;
            min-width: 100%;
            padding: 0 5px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .head {
                position: -webkit-sticky;
                position: sticky;
                top: 0;
                min-width: 100%;
                height: 60px;
                background: #f3f3f3;
                z-index: 10;
                border-radius: 5px;
                display: flex;

                .item {
                    padding: 0 20px;
                    width: 200px;
                    height: 100%;
                    font-size: 15px;
                    color: #abb4b9;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .body {
                min-width: 100%;
                max-width: 100%;

                .row {
                    position: relative;
                    height: 50px;
                    z-index: 1;
                    border: 1px solid transparent;
                    border-radius: 5px;
                    display: flex;
                    -webkit-transition: all 0.3s;
                    transition: all 0.3s;
                    -webkit-transition-timing-function: ease;
                    transition-timing-function: ease;
                    box-sizing: border-box;

                    &:hover {
                        z-index: 5;
                        border-color: #e3e3e3;
                        box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
                    }

                    &:nth-child(2n) {
                        background: #f9f9f9;
                    }

                    .column {
                        padding: 0 20px;
                        width: 200px;
                        height: 100%;

                        .column-box {
                            margin: 3px 0;
                            width: 100%;
                            height: ~"calc(100% - 3px)";
                            letter-spacing: 1px;
                            color: #000;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            overflow-x: auto;
                            overflow-y: hidden;
                            -webkit-overflow-scrolling: touch;

                            &::-webkit-scrollbar {
                                height: 5px;
                            }

                            .text-box {
                                font-size: 13px;
                                max-width: 90%;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }

                            &:hover .text-box {
                                overflow: initial;
                                text-overflow: initial;
                            }

                            .normal-c {
                                color: #07cd6f;
                            }

                            .normal-bg {
                                background: #07cd6f;
                            }

                            .empty-c {
                                color: #ffbb3a;
                            }

                            .empty-bg {
                                background: #ffbb3a;
                            }

                            .subclass-box {
                                cursor: pointer;
                                display: flex;
                                align-items: center;
                                -webkit-transition: all 0.3s;
                                transition: all 0.3s;
                                -webkit-transition-timing-function: ease;
                                transition-timing-function: ease;

                                .point {
                                    width: 8px;
                                    height: 8px;
                                    border-radius: 100%;
                                }

                                .text {
                                    margin-left: 10px;
                                }
                            }
                        }

                        .column-box-null {
                            color: #666;
                        }
                    }
                }

                .empty {
                    text-align: center;
                    font-size: 15px;
                    color: #888;
                    line-height: 25px;
                    position: absolute;
                    top: 80px;
                    right: 0;
                    left: 0;
                }
            }
        }

        .operating-box {
            background: #fff;
            z-index: 10;
            min-height: 100%;
            padding-right: 5px;
            flex-grow: 1;
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .operating-title {
                position: sticky;
                top: 0;
                min-width: 100%;
                padding: 0 30px;
                height: 60px;
                font-size: 15px;
                color: #abb4b9;
                font-weight: bold;
                background: #f3f3f3;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                border-radius: 5px;
            }

            .operating-item {
                padding: 0 30px;
                height: 50px;
                letter-spacing: 1px;
                color: #000;
                min-width: 100%;
                box-sizing: border-box;
                display: flex;
                align-items: center;

                &:nth-child(2n-1) {
                    background: #f9f9f9;
                }

                .button-box {
                    margin-right: 10px;

                    &:last-child {
                        margin-right: 0;
                    }

                    a,
                    button {
                        cursor: pointer;
                        padding: 5px 12px;
                        color: #fff;
                        font-size: 14px;
                        border: 0;
                        display: flex;
                        -webkit-transition: all 0.3s;
                        transition: all 0.3s;
                        -webkit-transition-timing-function: ease;
                        transition-timing-function: ease;
                        border-radius: 5px;

                        &:hover {
                            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
                        }
                    }
                }

                .edit {
                    background: #00b3d9;
                }

                .delete {
                    background: #ff5722;
                }

                .entity {
                    background: #2faaf7;
                }
            }
        }

        .operating-suspension {
            position: sticky;
            right: 0;
            box-shadow: -5px 0 5px rgba(0, 0, 0, 0.1);

            .operating-title {
                border-radius: 0 5px 5px 0;
            }
        }
    }
}
</style>
